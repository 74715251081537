import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import cs from 'classnames';

import { GTMEventLogger } from 'utils/GTMEventLogger';

import { GTMEvents } from 'constants/enums';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';

import styles from './Header.module.scss';

const Header = () => {
  const location = useLocation();
  const coloredBgPaths = ['/', '/employers'];

  const headerClass = cs(styles.header, {
    [styles.coloredBg]: coloredBgPaths.includes(location.pathname)
  });

  useEffect(() => {
    GTMEventLogger(GTMEvents.SessionStart);
  }, []);

  return (
    <header className={headerClass}>
      <NavLink to="/" end className={styles.logo}>
        <Logo />
      </NavLink>
      <nav className={styles.nav}>
        <NavLink
          to="/"
          end
          onClick={() => GTMEventLogger(GTMEvents.HomeClick)}
          className={({ isActive }) => cs({ [styles.active]: isActive })}
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => cs({ [styles.active]: isActive })}
          onClick={() => GTMEventLogger(GTMEvents.AboutUsClick)}
        >
          About us
        </NavLink>
        <NavLink
          to="/employers"
          className={({ isActive }) => cs({ [styles.active]: isActive })}
          onClick={() => GTMEventLogger(GTMEvents.EmployersPageClick)}
        >
          For Employers
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
