import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { App as AntApp, ConfigProvider } from 'antd';
import RenderRoutes from 'routes/components/RenderRoutes';

import { AppDispatch } from 'app/store';

import Loader from 'layouts/Suspense';

import GDPRPopup from 'components/GDPRPopup/GDPRPopup';

import { fetchAllDictionaries } from 'features/dictionaries/dictionariesThunks';

import theme from './config/antdThemeConfig';

const App: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  dispatch(fetchAllDictionaries());

  return (
    <ConfigProvider theme={theme}>
      <AntApp>
        <Loader>
          <>
            <Router>
              <RenderRoutes />
            </Router>
            <GDPRPopup />
          </>
        </Loader>
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
