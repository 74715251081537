export default {
  token: {
    fontFamily: `'Rubik', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
    borderRadius: 4
  },
  components: {
    Layout: {
      bodyBg: '#FFFFFF',
      colorText: '#677179'
    },
    Select: {
      fontSize: 14
    },
    Input: {
      fontSize: 14
    },
    Table: {
      fontSize: 14
    }
  }
};
