import { Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { Button, Checkbox } from 'antd';

import { setupAnalytics } from 'utils/analytics';
import { saveGDPRSettings } from 'utils/localStorage';

import { GDPROptions } from 'constants/constants';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './CustomizePrivacySettings.module.scss';

interface CustomizePrivacySettingsProps {
  toggleCustomizeSettings: () => void;
  setVisible: (value: boolean) => void;
  setGDPR: Dispatch<SetStateAction<boolean>>;
}

const CustomizePrivacySettings = ({
  toggleCustomizeSettings,
  setVisible,
  setGDPR
}: CustomizePrivacySettingsProps): ReactElement => {
  const [checkedOptions, setCheckedOptions] = useState<any>([
    GDPROptions.ANALYTICS,
    GDPROptions.FUNCTIONAL_COOKIES,
    GDPROptions.TARGETING_COOKIES
  ]);
  const checkIsSettingSet = (setting: string): boolean =>
    checkedOptions.indexOf(setting) > -1;

  const OptionRequiredAnalytics = (
    <div className={styles.OptionHolder}>
      <h3
        className={
          checkIsSettingSet(GDPROptions.ANALYTICS)
            ? styles.highlightedOption
            : ''
        }
      >
        Essential Cookies
      </h3>
      <p>
        The minimum data necessary for us to provide basic functionality.
        Without these cookies, the web application cannot work correctly.
      </p>
    </div>
  );
  const OptionFunctionalCookies = (
    <div className={styles.OptionHolder}>
      <h3
        className={
          checkIsSettingSet(GDPROptions.FUNCTIONAL_COOKIES)
            ? styles.highlightedOption
            : ''
        }
      >
        Functional
      </h3>
      <p>
        Functional cookies help to perform certain functionalities like sharing
        the content of the website on social media platforms, collect feedbacks,
        and other third-party features.
      </p>
    </div>
  );
  const OptionTargetingCookies = (
    <div className={styles.OptionHolder}>
      <h3
        className={
          checkIsSettingSet(GDPROptions.TARGETING_COOKIES)
            ? styles.highlightedOption
            : ''
        }
      >
        Advertisement
      </h3>
      <p>
        Advertisement cookies are used to provide visitors with relevant ads and
        marketing campaigns. These cookies track visitors across websites and
        collect information to provide customized ads.
      </p>
    </div>
  );

  const onAccept = (): void => {
    const settings: any = {
      [GDPROptions.ANALYTICS]: checkIsSettingSet(GDPROptions.ANALYTICS),
      [GDPROptions.FUNCTIONAL_COOKIES]: checkIsSettingSet(
        GDPROptions.FUNCTIONAL_COOKIES
      ),
      [GDPROptions.TARGETING_COOKIES]: checkIsSettingSet(
        GDPROptions.TARGETING_COOKIES
      )
    };

    saveGDPRSettings(settings);
    setGDPR(settings);
    setVisible(false);
    setupAnalytics();
  };

  const onDecline = (): void => setVisible(false);

  return (
    <div className={styles.Container}>
      <div className={styles.ContainerInner}>
        <h3 className={styles.Header}>
          Customize Privacy Settings
          <Button
            type="link"
            className={`${styles.NoButton} link-btn`}
            onClick={onDecline}
          >
            <CloseIcon />
          </Button>
        </h3>
        <p className={styles.MainText}>
          To give you personalized service, we (and the third-parties we work
          with) use cookies and similar technologies for things like: making
          Joblio easier to use, provide enhanced functionality, personalization,
          and user experience, recognize when you have signed in, analyze how
          Joblio is used
        </p>
        <Checkbox.Group
          className={styles.optionsContainer}
          options={[
            {
              label: OptionRequiredAnalytics,
              value: GDPROptions.ANALYTICS,
              disabled: true
            },
            {
              label: OptionFunctionalCookies,
              value: GDPROptions.FUNCTIONAL_COOKIES
            },
            {
              label: OptionTargetingCookies,
              value: GDPROptions.TARGETING_COOKIES
            }
          ]}
          value={checkedOptions}
          onChange={(checkedValue): void => setCheckedOptions(checkedValue)}
        />
        <div className={styles.ContainerButton}>
          <Button
            type="link"
            onClick={toggleCustomizeSettings}
            className={`${styles.BackButton} btn btn-small`}
          >
            <span>Back</span>
          </Button>
          <Button
            type="primary"
            className={`${styles.AcceptButton} btn btn-small`}
            onClick={onAccept}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomizePrivacySettings;
