export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum Dictionaries {
  Language = 'LANGUAGE',
  JobStatus = 'JOB_STATUS',
  Industry = 'INDUSTRY',
  ClientCountry = 'CLIENT_COUNTRY',
  LanguageLevel = 'LANGUAGE_LEVEL',
  Skillset = 'SKILLSET',
  ApplicantStatus = 'APPLICANT_STATUS',
  ClientPrivatePublic = 'CLIENT_PRIVATE_PUBLIC',
  ClientStatus = 'CLIENT_STATUS',
  ClientDomesticOver = 'CLIENT_DOMESTIC_OVER',
  ClientYearsInBus = 'CLIENT_YEARS_IN_BUS',
  JobType = 'JOB_TYPE',
  PayrollRates = 'PAYROLL_RATES',
  JobseekerStage = 'JOBSEEKER_STAGE',
  Education = 'EDUCATION',
  JobShift = 'JOB_SHIFT',
  ImmigrationRoutes = 'IMMIGRATION_ROUTES',
  SourcePlatform = 'SOURCE_PLATFORM',
  JobseekerStageTag = 'JOBSEEKER_STAGE_TAG',
  ApplicationStage = 'APPLICATION_STAGE',
  ApplicationStageTag = 'APPLICATION_STAGE_TAG',
  JobFeeStructure = 'JOB_FEE_STRUCTURE'
}

export enum GTMEvents {
  SessionStart = 'Joblioco_session_start', // User lands on joblio.co
  LogoClick = 'Joblioco_logo', // User clicks on the Joblio logo at the top left to land on the homepage
  HomeClick = 'Joblioco_home', // User clicks on “Home” page
  AboutUsClick = 'Joblioco_about_us', // User clicks on “About Us” page
  EmployersPageClick = 'Joblio.co_employers', // User clicks on “For Employers” page
  JobseekersPageClick = 'Joblioco_jobseekers', // User clicks on “For Jobseekers” page
  FooterPrivacyPolicyClick = 'Joblioco_footer_privacypolicy', // User clicks on “Privacy policy” page from footer
  FooterTermsClick = 'Joblioco_footer_termsofuse', // User clicks on “Terms and Conditions” page from footer
  FooterCookiePolicyClick = 'Joblioco_footer_cookiepolicy', // User clicks on “Cookie Policy” page from footer
  HomeLookingForWorkersClick = 'Joblioco_home_looking_for_workers_link', // User clicks on “Get started” hyperlink under the “Looking for workers?” homepage section
  HomeLookingForWorkClick = 'Joblioco_home_looking for work_link', // User clicks on “Get started” hyperlink under the “Looking for work?” homepage section
  YoutubeClick = 'Joblioco_youtube', // User clicks on the youtube hyperlink at the bottom of the page
  FacebookClick = 'Joblioco_facebook', // User click on the facebook hyperlink at the bottom of the page
  LinkedInClick = 'Joblioco_linkedin', // User click on the linkedin hyperlink at the bottom of the page
  JobSearchLinkClick = 'Joblioco_start_your_job_search_link', // User click on the hyperlink to get redirected to JoinApp (bottom of the page)
  HomeTestimonialsScroll = 'Joblioco_home_testimonials_scroll', // User scrolls through the testimonials list on the for homepage using arrows
  HomeContactFormSubmit = 'Joblioco_home_contact_form_submit', // User submits the form from the contact us intake form on the homepage
  HomeContactFormAbandonment = 'Joblioco_home_contact_form_abandonment', // User filled out the “contact” form on the homepage but haven’t submitted it
  HomeEmployerSignupClick = 'Joblioco_home_employer_signup_link', // User click on the “here” hyperlink of the “looking to hire?” section of the homepage
  HomeAmbassadorSignupClick = 'Joblioco_home_ambassador_signup_link', // User click on the hyperlinked email of the “looking to represent Joblio?” section of the homepage
  HomeJobseekerSignupClick = 'Joblioco_home_jobseeker_signup_link', // User clicks on the “join.joblio.co” hyperlink of the “looking for work?” section of the homepage
  HomeMediaSignupClick = 'Joblioco_home_media_signup_link', // User clicks on the hyperlinked email under the “Media requests?” section oh the homepage
  AboutFormSubmit = 'Joblioco_about_form_submit', // User submits the form on the “about us” page
  AboutFormAbandonment = 'Joblioco_about_form_abandonment', // User filled out the “about us” form but haven’t submitted it
  AboutAdvisorsScroll = 'Joblioco_about_advisors_scroll', // User scrolls through the advisory list on the about us page using arrows
  ForEmployersTestimonialsScroll = 'Joblioco_foremployers_testimonials_scroll', // User scrolls through the testimonials list on the for employers page using arrows
  ForEmployersFormSubmit = 'Joblioco_foremployers_form_submit', // User submits the form on the “for employers” page
  ForEmployersFormAbandonment = 'Joblioco_foremployers_form_adbandonment' // User filled out the “foremployers” form but haven’t submitted it
}
