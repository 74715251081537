import { ReactElement, lazy } from 'react';

import RouteWithLayout from 'routes/components/RouteWithLayout';

import { ABOUT, EMPLOYERS, MAIN, PRIVACY_POLICY } from '../constants/path';

const HomePage = lazy(() => import('pages/Home/Home'));
const AboutPage = lazy(() => import('pages/About/About'));
const EmployersPage = lazy(() => import('pages/Employers/Employers'));
const PrivacyPolicyPage = lazy(
  () => import('pages/PrivacyPolicy/PrivacyPolicy')
);

type RouteObject = {
  path: string;
  key: string;
  element: ReactElement;
  children?: RouteObject[];
};

const createRoute = (
  path: string,
  key: string,
  element: ReactElement,
  children: RouteObject[] = []
): RouteObject => ({
  path,
  key,
  element,
  children
});

const getRoutes = (): RouteObject[] => [
  createRoute(MAIN, 'MAIN_ROOT', <RouteWithLayout />, [
    createRoute(MAIN, 'MAIN', <HomePage />),
    createRoute(ABOUT, 'ABOUT', <AboutPage />),
    createRoute(EMPLOYERS, 'EMPLOYERS', <EmployersPage />),
    createRoute(PRIVACY_POLICY, 'PRIVACY_POLICY', <PrivacyPolicyPage />)
  ])
];

export default getRoutes;
