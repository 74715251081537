import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Button } from 'antd';

import { setupAnalytics } from 'utils/analytics';
import { saveGDPRSettings } from 'utils/localStorage';

import { URL_COOKIE_POLICY } from 'constants/constants';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './PrivacySettings.module.scss';

interface PrivacySettingsProps {
  toggleCustomizeSettings: () => void;
  setVisible: (visible: boolean) => void;
  setGDPR: Dispatch<SetStateAction<boolean>>;
}

const PrivacySettings = ({
  toggleCustomizeSettings,
  setVisible,
  setGDPR
}: PrivacySettingsProps): ReactElement => {
  const onAccept = (): void => {
    const settings: any = { A: true, F: true, T: true };
    saveGDPRSettings(settings);
    setGDPR(settings);
    setVisible(false);
    setupAnalytics();
  };

  const onDecline = (): void => setVisible(false);

  return (
    <div className={styles.ContainerSettings}>
      <div className={styles.ContainerInner}>
        <div className={styles.ContainerMain}>
          <h3 className={styles.Header}>
            We care about your privacy
            <Button
              type="link"
              className={`${styles.NoButton} link-btn`}
              onClick={onDecline}
            >
              <CloseIcon />
            </Button>
          </h3>
          <div className={styles.Text}>
            <p>
              To offer you a more personalized experience, we (and the
              third-parties we work with) collect info on how and when you use
              Joblio. It helps us to remember your details, provide
              functionality and show relevant ads and improve our services. More
              info in our &nbsp;
              <a
                // href={URL_COOKIE_POLICY}
                // target="_blank"
                href="#"
                rel="noreferrer"
                // aria-label={URL_COOKIE_POLICY}
              >
                Cookie Policy
              </a>
              .
            </p>
            <p>
              Please select “OK” to allow all the cookies. Or choose “Cookie
              Preferences” to customize your settings and opt out of all or some
              non-essential cookies.
            </p>
          </div>
        </div>
        <div className={styles.ContainerActions}>
          <Button
            type="link"
            className={`${styles.CustomizeSettings} link-btn`}
            onClick={(): void => toggleCustomizeSettings()}
          >
            Cookie preferences
          </Button>
          <Button
            type="primary"
            className={`${styles.AcceptButton} btn btn-small`}
            onClick={onAccept}
          >
            Accept all
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;
