import { FormattedOption, RawDictionaryData } from 'types/dictionaries';

const formatDictionaryData = (data: RawDictionaryData): FormattedOption[] => {
  const formatData = (
    obj: RawDictionaryData,
    parentKey?: string
  ): FormattedOption[] => {
    return Object.keys(obj).reduce<FormattedOption[]>((acc, key) => {
      const value = obj[key];
      if (typeof value === 'string') {
        acc.push({ value: key, label: value, ...(parentKey && { parentKey }) });
      } else {
        acc.push(...formatData(value, key));
      }
      return acc;
    }, []);
  };
  return formatData(data);
};

export default formatDictionaryData;
