import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';
import responseParser from 'utils/api/responseParser';

import { HOST } from 'constants/endpoints';

interface APIConfig {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: HeadersInit;
  body?: string | FormData;
  includeContentType?: boolean;
}

const fetchApi = (path: string, config: APIConfig): Promise<any> => {
  const headers: HeadersInit = {
    ...(config.includeContentType !== false &&
    !(config.body instanceof FormData)
      ? { 'Content-Type': 'application/json' }
      : {}),
    ...config.headers
  };

  const fullConfig: RequestInit = {
    ...config,
    headers: headers
  };

  const url = `${HOST}${path}`;

  return fetch(url, fullConfig)
    .then(checkStatus)
    .then((data) => responseParser(data))
    .catch(errorParser);
};

export const get = (path: string, headers?: HeadersInit): Promise<any> =>
  fetchApi(path, { method: 'GET', headers });

export const post = (
  path: string,
  body: object,
  headers?: HeadersInit
): Promise<any> =>
  fetchApi(path, { method: 'POST', body: JSON.stringify(body), headers });

export default { get, post };
