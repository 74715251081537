import { GTMEvents } from 'constants/enums';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const GTMEventLogger = (event: GTMEvents): void => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push(event);
};
