import responseParser from 'utils/api/responseParser';

import { ErrorResponse } from 'types/api';

const errorParser = async (error: any): Promise<never> => {
  if (!navigator.onLine) {
    throw new Error('No internet connection');
  }

  try {
    const response = error.response
      ? await responseParser(error.response)
      : null;
    const errorDetails: ErrorResponse = {
      message: error.message || 'Unknown error',
      status: error.response ? error.response.status : 500,
      data: response
    };

    console.error(errorDetails);
    throw errorDetails;
  } catch (parseError) {
    console.error(parseError);
    throw parseError;
  }
};

export default errorParser;
