import { ReactElement } from 'react';
import BaseLayout from 'layouts/BaseLayout';
import { Outlet } from 'react-router-dom';

const RouteWithLayout = ({ config = {} }): ReactElement => (
  <BaseLayout {...config}>
    <Outlet />
  </BaseLayout>
);

export default RouteWithLayout;
