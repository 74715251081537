import { GTM_ID } from 'config/gtmConfig';

import { getGDPRSettings } from './localStorage';

const isLocalhost = window.location.hostname === 'localhost';
const isTestURL = window.location.href.includes('landing-test');
const blockAnalytics = isLocalhost || isTestURL;

const isGTMInitialized = (): boolean => !!(window as any).dataLayer;

const addScript = (src: string, innerHTML?: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.async = true;
  if (src) {
    script.src = src;
  }
  if (innerHTML) {
    script.innerHTML = innerHTML;
  }
  document.head.appendChild(script);
  return script;
};

const initializeGTM = (): void => {
  if (isGTMInitialized()) return;
  addScript(
    '',
    `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');
  `
  );

  // Create noscript
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.insertBefore(noscript, document.body.firstChild);
};

export const setupAnalytics = (): void => {
  const gdprSettings = getGDPRSettings();
  if (blockAnalytics) {
    console.info('Analytics blocked for localhost or test URL.');
    return;
  }

  if (gdprSettings.F) {
    console.info('Initializing GTM with GDPR consent.');
    initializeGTM();
  } else {
    console.info('Analytics disabled due to GDPR settings.');
  }
};
