import { Gutter } from 'antd/es/grid/row';

export const GDPR_SETTINGS = 'cnst';

export const GDPROptions = {
  ANALYTICS: 'A',
  FUNCTIONAL_COOKIES: 'F',
  TARGETING_COOKIES: 'T'
};

export const SECTION_ROW_GUTTER = [16, 16] as [Gutter, Gutter];

export const DOMESTIC_OVERSEAS_SORT = [
  'Domestic',
  'International',
  'Domestic & International'
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const URL_COOKIE_POLICY = 'https://joblio.co/en/cookie-policy/';
export const SUPPORT_TELEGRAM = 't.me/joblio_co';
export const FACEBOOK = 'https://www.facebook.com/JoblioGlobal/';
export const LINKEDIN = 'https://www.linkedin.com/company/joblio/';
export const YOUTUBE = 'https://www.youtube.com/c/Joblioglobal';
