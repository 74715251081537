import { GTMEventLogger } from 'utils/GTMEventLogger';

import { FACEBOOK, LINKEDIN, YOUTUBE } from 'constants/constants';
import { GTMEvents } from 'constants/enums';

import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg';

import styles from './styles.module.scss';

const SocialNetworks = () => {
  return (
    <div className={styles.socialContainer}>
      <a
        href={YOUTUBE}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => GTMEventLogger(GTMEvents.YoutubeClick)}
      >
        <YoutubeIcon />
      </a>
      <a
        href={FACEBOOK}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => GTMEventLogger(GTMEvents.FacebookClick)}
      >
        <FacebookIcon />
      </a>
      <a
        href={LINKEDIN}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => GTMEventLogger(GTMEvents.LinkedInClick)}
      >
        <LinkedinIcon />
      </a>
    </div>
  );
};

export default SocialNetworks;
