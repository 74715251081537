import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { GTMEventLogger } from 'utils/GTMEventLogger';

import { URL_COOKIE_POLICY } from 'constants/constants';
import { GTMEvents } from 'constants/enums';
import { PRIVACY_POLICY } from 'constants/path';

import { ReactComponent as ArrowRight } from 'assets/icons/blueArrowRight.svg';
import { ReactComponent as JoblioLogo } from 'assets/icons/joblioMonoColorLogo.svg';

import SocialNetworks from './SocialNetworks';
import styles from './styles.module.scss';

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();
  const copyRight = `Copyright © ${currentYear} Joblio`;
  return (
    <footer className={styles.container}>
      <div className={styles.firstRow}>
        <JoblioLogo className={styles.footerLogo} />
        <SocialNetworks />
        <div>
          <a
            href="https://join.joblio.co/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.startNewJobButton}
            onClick={() => GTMEventLogger(GTMEvents.JobSearchLinkClick)}
          >
            <JoblioLogo className={styles.smallLogo} />
            <span>Start your job search now!</span>
            <ArrowRight className={styles.arrow} />
          </a>
        </div>
      </div>
      <div className={styles.extraText}>
        Redefining Recruitment through Advanced Talent Sourcing and Retention.
      </div>
      <div className={styles.termsContainer}>
        {copyRight} | All Rights Reserved |{' '}
        <a href="#" onClick={() => GTMEventLogger(GTMEvents.FooterTermsClick)}>
          Terms and Conditions
        </a>{' '}
        |{' '}
        <NavLink
          to={PRIVACY_POLICY}
          onClick={() => GTMEventLogger(GTMEvents.FooterPrivacyPolicyClick)}
        >
          Privacy Policy
        </NavLink>{' '}
        |{' '}
        <a
          // href={URL_COOKIE_POLICY}
          // target="_blank"
          href="#"
          rel="noopener noreferrer"
          onClick={() => GTMEventLogger(GTMEvents.FooterCookiePolicyClick)}
        >
          Cookie Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
